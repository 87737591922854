@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;500;700&display=swap');

h3 {
    font-family: 'Plus Jakarta Sans';
    font-size: 30px;
    font-weight: bold;
    margin-top: 38px;
}
p {
    font-family: 'Plus Jakarta Sans';
    margin: 16px 0px 16px 0px;
    font-size: 21px;
    color: rgb(63, 63, 63) ;
}
.page-container {
    max-width: 768px !important;
    margin: auto;
}

code {
    font-family: 'IBM Plex Mono', Courier, monospace !important;
}

@media only screen and (max-width: 600px) {
    p {
        font-size: 18px;
    }

    h3 {
        font-size: 24px;
    }
  }
