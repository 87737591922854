@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;500;700&display=swap');
html, body {
  background: #fcfcfc;
  overflow-x: hidden;
  
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  background: #6d6d6d !important;
}

.w-blog {
  width: 300px;
  height: 350px;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
